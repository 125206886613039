
export const LColor = {
  red: "#f00",
  green: "#0f0",
  blue: "#00f",
  yellow: "#ff0",
  cyan: "#0ff",
  magenta: "#f0f",
  white: "#fff",
  black: "#000",
  gray: "#888",
  grey: "#888",
  orange: "#f80",
  purple: "#808",
  pink: "#f08",
  teal: "#0f8",
  lime: "#8f0",
  brown: "#880",
  maroon: "#800",
  navy: "#008",
  olive: "#880",
  gold: "#fd0",
  silver: "#ccc",
  bronze: "#b84",
  crimson: "#c00",
  indigo: "#408",
  violet: "#808",
  yellowgreen: "#8f0",
  skyblue: "#08f",
  tan: "#d88",
  beige: "#f88",
  mint: "#8f8",
  lavender: "#88f",
  peach: "#f88",
  apricot: "#f88",
  plum: "#808"
}

export const Logger = (s: string, c: string) => {
  console.log("%c " + s + "", "color:" + c + ";")
}