import { useRef, useState, useMemo } from 'react';
import { LColor, Logger } from './Logger';
import { VideoPlayerModel } from './assetTypes';


export const useVideoPlayers = (videos: VideoPlayerModel[]) => {

  const v = useRef(videos)
  const players = useRef<Map<VideoPlayerModel, HTMLVideoElement>>(new Map<VideoPlayerModel, HTMLVideoElement>())
  const numPlayersLoaded = useRef(0) // internal because oncanplay is called each time it loops
  const [loaded, setLoaded] = useState(false) // external
  

  useMemo(() => {
    v.current.forEach( (videoPlayerModel,i ) => {
      Logger("loading video "+ videoPlayerModel.url, LColor.yellow)
      const element = document.createElement("video")
      element.src = videoPlayerModel.url
      element.crossOrigin = "Anonymous"
      element.muted = false
      element.playsInline = true
      element.controls = false

      // element.playbackRate = videoPlayerModel.playbackRate
      // element.currentTime = videoPlayerModel.startTime
      // element.volume = videoPlayerModel.volume
      // element.loop = videoPlayerModel.loop

      // first frame of the video has finished loading
      element.onloadeddata = () => { 
        Logger(("data loaded " + videoPlayerModel.url), LColor.yellow)
      }

      // The browser can play the media, but estimates that not enough data has been loaded to play the 
      // media up to its end without having to stop for further buffering of content.
      // DOES NOT GET CALLED ON IOS / SAFARI
      // element.oncanplay = () => { 
      //   Logger(("can play " + videoPlayerModel.url), LColor.yellow)
      // }

      // using this to trigger element is ready to play for ios/safari bug (oncanplay does not work on auto play)
      element.onloadedmetadata = () => {
        Logger(("loaded metadata " + videoPlayerModel.url), LColor.yellow)
        numPlayersLoaded.current += 1
        if(numPlayersLoaded.current === v.current.length){
          // setTimeout(() => {
            Logger("loaded ALL video ", LColor.yellow)
            setLoaded(true) 
          // }, 2000)
        } 
      }

      // The browser estimates it can play the media up to its end without stopping for content buffering
      element.oncanplaythrough = () => { 
        Logger(("can play through " + videoPlayerModel.url), LColor.yellow)
      }

      // playback has begun
      element.onplay = () => { 
        Logger(("onplay " + videoPlayerModel.url), LColor.yellow)
      }

      element.onended = () => {
        Logger(("onended " + videoPlayerModel.url), LColor.yellow)
      }

      players.current.set(videoPlayerModel, element)

    })
  }, [])

  const playAllVideos = useRef(() => {
    players.current.forEach( (player, model) => {
      player.play()
    })
  })

  const pauseAllVideos = useRef(() => {
    players.current.forEach( (player, model) => {
      player.pause()
    })
  })

  return {
    players,
    loaded,
    playAllVideos: playAllVideos.current,
    pauseAllVideos: pauseAllVideos.current
  }
}