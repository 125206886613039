import './App.css';
import { useRef, useState } from 'react';
import { VideoPlayerModel } from './assetTypes';
import { useVideoPlayers } from './VideoCollection';

const videoModelA: VideoPlayerModel = {
  url: "https://bathhara.s3.ap-south-1.amazonaws.com/EMImprov7.mp4", 
  playbackRate: 1,
  startTime: 0,
  volume: 0.7,
  loop: false, 
  vtt: "", 
}


function App() {

  const imgSrc = "IMG_7185.JPG"
  
  const RenderPlayer = (props: {
    videoModel: VideoPlayerModel
  }) => {

    const viewModelRef = useRef([props.videoModel])
    const { playAllVideos, pauseAllVideos, loaded } = useVideoPlayers(viewModelRef.current)
    const [toggle, setToggle] = useState(false)




    return (
      <div className='grid place-items-center'>
      {
        loaded ? <p>
          <button className='w-40 px-4 py-4 mb-8 text-white rounded-full bg-slate-800'
            onClick={ () => {
              if(toggle){
                pauseAllVideos()
              }else{
                playAllVideos()
              }
              setToggle((t) => !t) 

              
            }}> { toggle ? "Pause" : "Play here"  }</button>
        </p> 
        :
        <p className='w-40 px-4 py-4 mb-8 text-white rounded-full bg-slate-800'>
          loading...
        </p>
      }
      </div>
  )}

  return (
    <div className="">
      <header className="" >
        <div className="min-h-screen text-center bg-bgreen ">

          <div className='grid pt-10 place-items-center'>
            <div 
                className="bg-gray-800 bg-no-repeat bg-cover w-80 h-80"
                style={{ backgroundImage : 'url('+imgSrc+')' }}>
            </div>
          </div>

          <p className="w-screen pt-4 pb-2 font-normal text-center text-white text-7xl">
            bathhara
          </p>


          <p className="px-4 pb-8 text-xl italic text-center text-white font-extralight ">
            Creating improvised music in response to 
            <br></br>
            this micro human experience in the 
            <br></br>
            vast interconnected universe. 
          </p>


          <p  className="w-screen text-xl text-center text-white font-extralight">
            Voice : 
          </p>
          <p  className="w-screen pb-4 text-2xl font-light text-center text-white underline">
            <a href='https://www.emmabathgate.com'>
            Emma Bathgate
            </a>
          </p>
          <p  className="w-screen text-xl text-center text-white font-extralight">
            Electro-acoustic Violin : 
          </p>
          <p  className="w-screen pb-12 text-2xl font-light text-center text-white underline">
          <a href='https://soh.la'>
            Steph OHara
            </a>
          </p>

          <p  className="w-screen text-xl font-light text-center text-white">
            Listen to a live recording: 
          </p>
          <p  className="w-screen pb-4 font-light text-center text-white text-1xl">
            Find a comfortable place to listen in or outdoors 
          <br></br>
            – and give yourself time and space to enjoy. 
          </p>
          <RenderPlayer videoModel={videoModelA}/>
          <p  className="w-screen text-xl font-light text-center text-white">
            Listen to 'the sky in your eyes' EP : 
            </p>
            <p  className="w-screen pb-4 font-light text-center text-white text-1xl">
            A collection of excerpts from improvised sessions 
          <br></br>
            recorded November 2022 - June 2023.  
            </p>
            <div className='flex items-center justify-center '>
              <p className='px-4 py-4 mb-8 text-white underline rounded-full w-62 bg-slate-800'>
                <a href='https://bathhara.bandcamp.com/album/the-sky-in-your-eyes'>Play on Bandcamp</a> 
              </p>
            </div>

          <p  className="w-screen text-xl font-thin text-center text-white">
            Contact :          
          </p>

          <p className="w-screen pt-2 pb-8 font-thin text-center text-white text-1xl">
            <a href = "mailto:us@bathhara.com" className='px-4 py-2 m-1 underline rounded-full bg-slate-800'>us@bathhara.com</a>
            {/* <a href = "mailto: abc@example.com" className='px-4 py-1 m-1 rounded-full bg-slate-800'>Instagram</a>
            <a href = "mailto: abc@example.com" className='px-4 py-1 m-1 rounded-full bg-slate-800'>YouTube</a> */}
          </p>


          <p  className="w-screen pt-4 pb-4 pl-10 pr-10 text-sm font-light text-center text-white bg-slate-800">
          Bathhara play and live on unceded lands of the Wurrundjeri peoples of the Kulin Nations 
          <br></br>
          - we pay our respects to their elders past present & future
          <br></br>

            & to all traditional owners of the many first nations people in Australia.
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;


/*

main image
artists image
words
about
contact
social media




*/